import { computed, ref } from 'vue'
import { useWindowSize } from '@vueuse/core'

const { width } = useWindowSize()
const isMobile = computed(() => width.value < 992)

export const currentTab = ref('')

export const setCurrentTab = (selectedTab: string):void => {
    currentTab.value = selectedTab
}

export const listingsTabs = ref<HTMLElement | null>(null)

export const listingsTabsContent = ref<HTMLElement | null>(null)

export const getStyle = (element: HTMLElement, style: keyof CSSStyleDeclaration): number => {
    return parseFloat(getComputedStyle(element)[style] as string) || 0
}

export  const normalizeTabsScroll = (scrollPositionMobile: number, scrollPositionMobileDesktop: number):void => {
    if(isMobile.value) {   
        window.scrollTo({
            top: scrollPositionMobile,
            behavior: 'smooth'
        })
    } else {
        window.scrollTo({
            top: scrollPositionMobileDesktop,
            behavior: 'smooth'
        })
    }
}

